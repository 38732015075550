import { type FC, useEffect, useMemo, useState } from 'react'

import type { ReactNotificationProps } from '@/types/components/notification'
import './notification.scss'
import NotificationItem from './NotificationItem'
import clsx from 'clsx'

export const Notification: FC<ReactNotificationProps> = ({ notification }) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (
      notification.notificationItems &&
      notification.notificationItems.length
    ) {
      const notificationInterval = setInterval(() => {
        // Rotate to the next notification
        setIndex(
          (prevIndex) =>
            (prevIndex + 1) % notification.notificationItems.length,
        )
      }, notification.timeBetween)

      // Clear the interval when the component is unmounted
      return () => clearInterval(notificationInterval)
    }
  }, [notification])

  const classes = useMemo(
    () => [
      'notification',
      { [`theme-${notification.theme}`]: notification.theme },
    ],
    [notification],
  )

  return (
    <div className={clsx(classes)}>
      {notification.notificationItems.map((notification, itemIndex) => {
        return (
          <NotificationItem
            isCurrent={index === itemIndex}
            notification={notification}
            key={notification._key}
          />
        )
      })}
    </div>
  )
}
