import type { FC } from 'react'

import type { LinkTextProps } from '@/types/components/link-text'
import Link from './Link'

const LinkText: FC<LinkTextProps> = ({
  link,
  className = '',
  reverse = false,
}) => {
  const { text } = link

  const classes = ['link', { 'link-reverse': reverse }, className]

  return (
    <Link link={link} classes={classes}>
      {text}
    </Link>
  )
}

export default LinkText
