import LinkText from '@/components/Link/LinkText'
import type { CMSNotificationItem } from '@/types/sanity'
import { clsx } from 'clsx'
import { useMemo, useRef, type FC } from 'react'

interface NotificationItemProps {
  notification: CMSNotificationItem
  isCurrent: boolean
}

const NotificationItem: FC<NotificationItemProps> = ({
  notification,
  isCurrent,
}) => {
  const classes = useMemo(
    () => [
      'notification__inner',
      { active: isCurrent },
      { inactive: !isCurrent },
    ],
    [notification, isCurrent],
  )
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div className={clsx(classes)} key={notification.link?.url} ref={ref}>
      <span className="notification__text">
        {notification.text}
        {notification.link && (
          <LinkText
            className="type-label"
            link={notification.link}
            reverse={true}
          />
        )}
      </span>
    </div>
  )
}

export default NotificationItem
